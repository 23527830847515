(function($) {
  Drupal.behaviors.csrToolBar = {
    attach: function(context) {
      $.curCSS = function (element, attrib, val) {
        $(element).css(attrib, val);
      };
      var csrToolBar = $('#csr_header_holder');
      //Adding 20px buffer height to avoid the overlapping with menu
      var bufferHeight = 20;
      if (csrToolBar.length > 0) {
        //Adding timeout to position the CSR toolbar after the menu loaded
        setTimeout(function() {
          var headerHeight = $('.site-header__main').height() + bufferHeight;
          csrToolBar.css('top', headerHeight + "px");
        }, 2000);
      }
    }
  };
})(jQuery);
